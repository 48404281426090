import * as React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="container text-center">
      <h1 className="section-heading">404: Page Not Found</h1>
      <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
    </section>
  </Layout>
);

export default NotFoundPage;
